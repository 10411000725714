import {
  CheckCircleFilled,
  CloseCircleFilled,
  DownloadOutlined,
  QuestionCircleFilled,
  UploadOutlined
} from '@ant-design/icons';
import { Alert, Button, Card, Form, FormProps, Space, Spin, Upload } from 'antd';
import { UploadState } from 'antd/es/upload/interface';
import { bulkOrganizationRemovalViaCsvFile, BulkUserRemovalResponse } from 'api';
import { CardHeader } from 'components/cards';
import { InputForm } from 'components/forms';
import { DarkModeContext } from 'layouts/app_layout';
import { useContext, useState } from 'react';
import { useParams } from 'react-router';
import { BodyRow, HeaderRow, Table, TableWrapper } from 'styled_components/tables';
import { normalizeFileFromUpload, toFormData } from 'utils/forms';

const { Item } = Form;

export const OrganizationBulkRemovalPage = () => {
  const { id } = useParams<{ id: string }>();
  const isDarkMode = useContext(DarkModeContext);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [response, setResponse] = useState<BulkUserRemovalResponse | null>(null);

  const onFinish: FormProps<{
    file: UploadState['fileList'];
  }>['onFinish'] = values => {
    const { file } = values;

    if (!id) {
      return;
    }
    setIsSubmitting(true);

    const data = toFormData({
      file: file[0].originFileObj
    });

    setResponse(null);
    return bulkOrganizationRemovalViaCsvFile(id, data)
      .then(result => {
        setResponse(result);
        setIsSubmitting(false);
      })
      .catch(() => {
        setIsSubmitting(false);
      });
  };

  const onDownloadErrors = () => {
    if (!response || response.data.errors.length === 0) {
      return;
    }

    const csvContent =
      'data:text/csv;charset=utf-8,' +
      `user_id,error\n` +
      response.data.errors.map(e => `${e.user_id},${e.message}`).join('\n');
    var encodedUri = encodeURI(csvContent);
    var link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', `${id}_bulk_removal_errors.csv`);
    document.body.appendChild(link);

    link.click();
  };

  return (
    <Card title={<CardHeader title="Bulk User Removal from Organization via CSV file" />}>
      <h1>CSV format:</h1>
      <TableWrapper>
        <Table className={isDarkMode ? 'dark-theme' : 'light-theme'}>
          <thead>
            <HeaderRow className={isDarkMode ? 'dark-theme' : 'light-theme'}>
              <th>user_id</th>
            </HeaderRow>
          </thead>
          <tbody>
            <BodyRow className={isDarkMode ? 'dark-theme' : 'light-theme'}>
              <td>UUID</td>
            </BodyRow>
          </tbody>
        </Table>
      </TableWrapper>

      <InputForm
        disabled={isSubmitting}
        name="bulk-removal-csv"
        onFinish={onFinish}
      >
        <div>
          <strong>User removal</strong>
          <p style={{ marginBottom: '1.5rem', marginTop: '0.25rem' }}>
            Users will be removed from the organization, unless they have no other organization
            memberships. If a user ID is not found for the selected organization, they are skipped.
          </p>
        </div>

        <Item
          getValueFromEvent={normalizeFileFromUpload}
          label="CSV file"
          name="file"
          required
          rules={[
            {
              validator: async (_, fileList: UploadState['fileList']) => {
                if (fileList.length < 1) {
                  return Promise.reject(new Error('Upload a file'));
                }
                return true;
              }
            }
          ]}
          valuePropName="fileList"
        >
          <Upload
            beforeUpload={() => false}
            maxCount={1}
            showUploadList={{
              showRemoveIcon: false
            }}
          >
            <Button icon={<UploadOutlined />}>Upload CSV file</Button>
          </Upload>
        </Item>
      </InputForm>

      {isSubmitting && (
        <div
          style={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
            marginTop: '20px'
          }}
        >
          <Spin
            size="large"
            tip="Processing..."
          />
        </div>
      )}

      {response && (
        <Space
          direction="vertical"
          style={{
            borderTop: '1px solid #f0f0f0',
            display: 'flex',
            marginTop: '10px',
            paddingTop: '40px'
          }}
        >
          <Alert
            description={
              <table>
                <thead>
                  <tr>
                    <th style={{ textAlign: 'left', width: '100px' }}>Users</th>
                    <th>Count</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <CheckCircleFilled style={{ color: isDarkMode ? '#74c700' : '#45b500' }} />{' '}
                      Removed
                    </td>
                    <td>{response.data.removed_count}</td>
                  </tr>
                  <tr>
                    <td>
                      <QuestionCircleFilled style={{ color: isDarkMode ? '#6bb2ff' : '#0057b5' }} />{' '}
                      Not found
                    </td>
                    <td>{response.data.not_found_count}</td>
                  </tr>
                  <tr>
                    <td>
                      <CloseCircleFilled style={{ color: isDarkMode ? '#ff6b6b' : '#a30000' }} />{' '}
                      Failed
                    </td>
                    <td>{response.data.failed_count}</td>
                  </tr>
                </tbody>
              </table>
            }
            message={<b>Results</b>}
            type="info"
          />

          {response.data.errors.length > 0 && (
            <TableWrapper>
              <Table className={isDarkMode ? 'dark-theme' : 'light-theme'}>
                <thead>
                  <HeaderRow className={isDarkMode ? 'dark-theme' : 'light-theme'}>
                    <th>user_id</th>
                    <th>error</th>
                  </HeaderRow>
                </thead>
                <tbody>
                  {response.data.errors.map((error, i) => (
                    <BodyRow
                      key={i}
                      className={isDarkMode ? 'dark-theme' : 'light-theme'}
                    >
                      <td>{error.user_id}</td>
                      <td>{error.message}</td>
                    </BodyRow>
                  ))}
                </tbody>
              </Table>
              <Button
                onClick={onDownloadErrors}
                style={{ marginTop: '1rem' }}
              >
                <DownloadOutlined /> Download errors
              </Button>
            </TableWrapper>
          )}
        </Space>
      )}
    </Card>
  );
};
