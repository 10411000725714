import { Card, FormProps, Spin } from 'antd';
import { getNetwork, updateNetwork, useFetch } from 'api';
import { CardHeader } from 'components/cards';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom-v5-compat';
import * as paths from 'utils/paths';

import { NetworkForm } from './network_form';

export const NetworkEditPage = () => {
  const { id } = useParams<{ id: string }>();
  const { data } = useFetch(getNetwork, [id]);

  const navigate = useNavigate();

  const onFinish: FormProps<{
    name: string;
    organization_managers: {
      created_at: string;
      id: string;
      name: string;
    }[];
    organization_members: {
      created_at: string;
      direct_contract: boolean;
      id: string;
      name: string;
    }[];
  }>['onFinish'] = values =>
    updateNetwork(id, {
      network: {
        name: values.name,
        organization_manager_ids: values.organization_managers.map(member => member.id),
        organization_member_ids: values.organization_members.map(member => member.id)
      }
    }).then(() => {
      navigate(paths.networksPath);
    });

  if (data?.data === undefined) return <Spin />;

  return (
    <Card title={<CardHeader title="Edit Network" />}>
      <NetworkForm
        initialData={data.data}
        name="network-update"
        onFinish={onFinish}
      />
    </Card>
  );
};
